import defaultSeo from '../../../common/constants/nextSEO'
import { featuredInData } from '../consts'
import { APP_BASE_PATH } from '../../../common/constants'

export const data = {
  featuredInData,
  debtReliefCalculator: {
    title: 'You could free up ${amount} on your monthly payment¹',
    subTitle: 'Select your debt amount to see how much cash you might free up',
    items: [
      {
        title: 'Pay less each month',
        lines: [
          {
            text: 'Freedom Debt Relief',
            iconCheck: true,
            val: '2',
            indexRefernece: '0',
            unit: '/mo',
            className: 'w-3/4',
            bgColor: 'rgb(239 245 255 / 1)',
          },
          {
            text: 'Current',
            iconCheck: false,
            val: '0',
            unit: '/mo',
            className: 'w-3/4  bg-gray-110',
          },
        ],
      },
      {
        title: 'Reduce what you owe',
        lines: [
          {
            text: 'Freedom Debt Relief',
            iconCheck: true,
            val: '3',
            indexRefernece: '1',
            unit: '',
            extra: 'est. total cost w/ fees',
            className: 'w-1/2',
            bgColor: 'rgb(239 245 255 / 1)',
          },
          {
            text: 'Current',
            iconCheck: false,
            val: '1',
            unit: '',
            extra: 'est. total cost w/ interest',
            className: 'w-1/2  bg-gray-110',
          },
        ],
      },
      {
        title: 'Get rid of debt faster',
        useStaticValues: true,
        lines: [
          {
            text: 'Freedom Debt Relief',
            iconCheck: true,
            val: '4 years',
            indexVal: 5,
            unit: ' ',
            className: 'w-1/2 bg-blue-135',
          },
          {
            text: 'Current',
            iconCheck: false,
            val: '4 yrs 9 mos',
            indexVal: 4,
            unit: '',
            className: 'w-3/4 bg-gray-110',
          },
        ],
      },
    ],
  },
  heroBanner: {
    title: 'Credit Card Debt Relief',
    text: '"It would have taken me 15 years to get out of debt. With Freedom, it\'s only going to take me 3."',
    name: '— Alejandra, FDR client',
    content: {
      title:
        'Get credit card debt relief \nfrom America’s top debt relief company',
      sliderTitle: 'Choose your debt amount',
      buttonText: 'Continue',
      phoneSpeakText: 'or Talk to a Certified Debt Consultant',
      number: '800-910-0065',
    },
    iconStrip: {
      clients: `Over&nbsp;
            <span class="font-bold">1 million</span>
                  &nbsp;clients enrolled`,
      noRisk: `Free,&nbsp;
              <span class="font-bold">no-risk</span>
                    &nbsp;credit card debt relief consultation`,
      debtResolved: `Over&nbsp;
            <span class="font-extrabold">$18 billion</span>
                        &nbsp;debt resolved since 2002`,
    },
  },
  heroBannerFooter: {
    content: {
      sliderTitle: 'How much debt do you have?',
      buttonText: 'Get your free evaluation',
      phoneSpeakText: 'Call us',
      number: '800-910-0065',
    },
    header: {
      title: 'See if you are eligible for debt relief',
      list: [
        'Personalized debt relief plan',
        'No impact to credit',
        'Only takes 3 minutes to see your options',
      ],
    },
  },
  debtSimple: {
    title: 'Credit card debt relief is simple',
    debtTitle: 'Tell us about your debt',
    debtText:
      'Talk to one of our Certified Debt Consultants to see if Freedom Debt Relief could be the right solution for your debt',
    saveTitle: 'See what you could save',
    saveText:
      'Find out how much time and money you could save by partnering with us to resolve your debt',
    enrollTitle: 'Enroll in our debt relief program',
    enrollText:
      'We will negotiate settlements with your creditors until all of your enrolled debt is behind you',
    footer:
      'You could begin your path to credit card debt relief in just a few days',
    button: {
      url: 'https://apply.freedomdebtrelief.com',
      text: 'Find debt relief options',
    },
  },
  programWorks: {
    title: 'How our debt relief program works',
    content: [
      ` Debt relief is an opportunity to put your credit card debt behind you
        without paying the full amount owed. Our debt experts negotiate with
        your creditors to get them to agree to settle for less than the full
        amount you owe, so you can resolve your debt for less and in less
        time than other debt solutions.`,
      `As a debt relief company that has been helping people for over 15
        years, we know how to create a custom debt relief program that gets
        you the best possible results.`,
      'There are 4 phases in the Freedom Debt Relief program:',
    ],
    steps: [
      {
        title: 'Build',
        text: 'Each month, you make a deposit into an FDIC-insured dedicated account that you control. As your account grows, we create a custom plan to get you out of debt.',
      },
      {
        title: 'Negotiate',
        text: 'After enough funds build up in your account, we negotiate with your creditors to settle your debts for significantly less than you owe.',
      },
      {
        title: 'Settle',
        text: 'Our expert negotiators work hard to get you the best savings possible. Every time we settle one of your debts, we contact you for authorization and approval.',
      },
      {
        title: 'Freedom',
        text: 'After the full settlement amount is paid on each account, you no longer owe your creditors. Your credit card debt is behind you!',
      },
    ],
    footer: {
      textFirstPart: `Every step of the way, our friendly customer service representatives
        are ready to answer your questions and offer support. Complete `,
      textSecondPart: ' to get started today.',
      link: {
        url: 'https://apply.freedomdebtrelief.com/home/estimated-debt',
        text: 'our simple online form',
      },
    },
  },
  partners: {
    title: 'We’re your debt relief partners, every step of the way',
    text: "Enroll in our credit card debt relief program, and it's no longer just you against your creditors. We’re on your side, ready to help you get out of credit card debt — and get on with your life. You don't have to go it alone anymore.",
  },
  clientStories: {
    title: 'CLIENT STORIES',
    subtite: 'Move forward in life after debt',
    footer: 'Individual results are not typical and will vary.',
    totalDebt: 'Total debt resolved',
    montlyPayments: 'Monthly payment',
    debtsResolved: 'Debts resolved',
    stories: [
      {
        img: '/next-assets/client-stories/molly.webp',
        name: 'Molly B.',
        pronoun: 'her',
        story:
          '“I feel so good, so relieved, and so happy to be moving forward with my life without debt"',
        videoLink: 'https://www.youtube.com/watch?v=tMowVbnPpzk',
        totalDebt: '$29,987',
        montlyPayment: '$562',
        debtsResolved: '6',
      },
      {
        img: '/next-assets/client-stories/patti.webp',
        name: 'Patti B.',
        pronoun: 'her',
        story:
          '“Within a year, my first card was paid off. Then the second, then the third...After only a few years, all my debts were paid off.”',
        videoLink: 'https://www.youtube.com/watch?v=s2FjhITmg0o',
        totalDebt: '$17,183',
        montlyPayment: '$362',
        debtsResolved: '4',
      },
      {
        img: '/next-assets/client-stories/ozzy.webp',
        name: 'Ozzy S.',
        pronoun: 'his',
        story:
          '“Right away, I had more money each month because of program costs so much less than what I was paying on my minimums.”',
        videoLink: 'https://www.youtube.com/watch?v=NVxTO5Ea2-Y&t=39s',
        totalDebt: '$22,738',
        montlyPayment: '$398',
        debtsResolved: '8',
      },
    ],
  },
  heroConsumerConcept: {
    title: 'Get rid of your debt with debt relief',
    subtitle:
      "“My credit card and loans together were two-thirds of what I was earning...now it's greatly reduced.”",
    footer: 'Individual results are not typical and will vary.',
    totalDebt: 'Total debt resolved',
    monthlyPayments: 'Monthly payment',
    timeToSettlement: 'Time to 1st settlement',
    debtsSettled: 'Debts settled',
    picture: {
      title: 'Alejandra G.',
      subtitle: 'Freedom client¹',
    },
    debtData: {
      totalDebt: '$17,183',
      monthlyPayments: '$362',
      timeToSettlement: '3 months',
      debtsSettled: '4',
    },
  },
}

export const espanolData = {
  featuredInData,
  seoData: {
    ...defaultSeo,
    title:
      'La Mejor Compañía de Alivio de Deudas de Tarjetas de Crédito | Freedom Debt Relief',
    description:
      'Freedom Debt Relief es el líder estadounidense en alivio de deudas de tarjetas de crédito y consolidación de deudas para ayudar a los clientes a reducir sus pagos mensuales sin bancarrota ni préstamos.',
    canonical: `${APP_BASE_PATH()}/es/espanol/`,
    openGraph: {
      ...defaultSeo.openGraph,
      description:
        'Freedom Debt Relief es el líder estadounidense en alivio de deudas de tarjetas de crédito y consolidación de deudas para ayudar a los clientes a reducir sus pagos mensuales sin bancarrota ni préstamos.',
      title:
        'La Mejor Compañía de Alivio de Deudas de Tarjetas de Crédito | Freedom Debt Relief',
      url: 'https://www.freedomdebtrelief.com/es/espanol/',
    },
  },
  heroBanner: {
    text: '"Me habría llevado 15 años salir de la deuda. Con Freedom, solo me llevará 3".',
    name: '- Alejandra, cliente actual de FDR',
    content: {
      title: 'Salga de la Deuda de Tarjeta de Crédito',
      subTitle:
        'De la principal empresa de alivio de la deuda de Estados Unidos',
      sliderTitle: 'Elija el monto de su deuda',
      buttonText: 'Seguir',
      phoneSpeakText: 'O hable con un Consejero de Deuda Certificado al ',
      number: '888-480-3328',
    },
    iconStrip: {
      clients: `Más de&nbsp;
            <span class="font-bold">1 millón</span>
                  &nbsp;clientes inscritos`,
      noRisk: `Consulta gratuita, <b>sin riesgo</b>`,
      debtResolved: `Más de&nbsp;
            <span class="font-extrabold">$18,000 millones</span>
                        &nbsp;de deudas resueltas desde 2002`,
    },
  },
  heroBannerFooter: {
    content: {
      sliderTitle: 'Seleccione su deuda para ver los ahorros estimados',
      buttonText: 'Obtenga su evaluación gratuita',
      phoneSpeakText: 'Llámanos',
      number: '800-910-0065',
    },
    header: {
      title: 'Vea si califica para el consiliar de la deuda',
      list: [
        'Plan personalizado de alivio de deuda',
        'Sin impacto al crédito',
        'Solo toma 3 minutos ver tus opciones',
      ],
    },
  },
  debtSimple: {
    title: 'Encuentre Alivio en 3 Sencillos Pasos',
    debtTitle: 'Hable Gratuitamente con un Consejero',
    debtText:
      'Revise sus deudas con un Consejero de Crédito Certificado, evaluaremos si califica y cuánto tiempo y dinero podría ahorrarle nuestro programa',
    saveTitle: 'Inscríbase en Nuestro Programa',
    saveText:
      'Nosotros nos comunicaremos con sus acreedores y trabajaremos con ellos para reducir la tasa de interés y evitar que le cobren cargos por demora',
    enrollTitle: 'Salga de la Deuda Más Rápido',
    enrollText:
      'Negociaremos con sus acreedores hasta que toda su deuda inscrita sea resuelta',
    button: {
      url: 'https://apply.freedomdebtrelief.com/espanol?utm_source=fdrsite-esp#/espanol/estimated_debt',
      text: 'Vea si cumple con los requisitos',
    },
  },
  programWorks: {
    title: 'Nuestro Programa de Alivio de la Deuda',
    content: [
      'El alivio de la deuda es una oportunidad para dejar atrás la deuda de tarjeta de crédito sin pagar el monto total. Nuestros expertos en deudas negociarán con sus acreedores para que acepten liquidar por menos del monto total, y así usted pueda resolver su deuda por menos y más rápido que otras soluciones de deuda.',
      'Como compañía de alivio de deuda, hemos estado ayudando a las personas durante más de 15 años, sabemos cómo crear un programa de alivio de deuda personalizado e individual que le brindará los mejores resultados posibles.',
    ],
    steps: [
      {
        title: 'ACUMULE',
        text: 'Todos los meses, hace un depósito en su cuenta de ahorros que ested controla, la cual es asegurada por la Corporación Federal de Seguro de Depósitos (Federal Deposit Insurance Corporation, FDIC).',
      },
      {
        title: 'NEGOCIE',
        text: 'Una vez que haya ahorrado lo suficiente en su cuenta, nosotros negociamos con sus acreedores para reducir su deuda significativamente.',
      },
      {
        title: 'LIQUIDE',
        text: 'Después de conseguirle el mayor ahorro posible, nos comunicamos con usted para que apruebe la liquidación.',
      },
      {
        title: 'LIBERTAD',
        text: 'Al pagar al suma total de cada liquidacion su deuda sera cosa del pasado!',
      },
    ],
  },
  partners: {
    title: 'Somos sus Socios de Alivio de la Deuda, en Cada Paso del Camino',
    text: 'Inscríbase en nuestro programa de alivio de deudas de tarjetas de crédito, de este modo ya no es sólo usted contra sus acreedores. Estamos de su lado, listos para ayudarlo a salir de la deuda de su tarjeta de crédito y continuar con su vida. Ya no tiene que hacerlo solo.',
  },
  clientStories: {
    title: 'HISTORIAS DE CLIENTES',
    subtite: 'More forward in life after debt',
    footer: 'Individual results are not typical and will vary.',
    whatchStory: 'Ver su historia',
    totalDebt: 'Total de Deudas Resueltas',
    montlyPayments: 'Pagos Mensuales',
    resolved: 'Resuelta en',
    debtsResolved: 'Deudas Resueltas',
    stories: [
      {
        img: '',
        name: 'Patti B.',
        story:
          '“Within a year, my first card was paid off. Then the second, then the third...After only a few years, all my debts were paid off.”',
        videoLink: 'https://www.youtube.com/watch?v=s2FjhITmg0o',
      },
      {
        img: '',
        name: 'Alenjandra',
        story:
          '“I had several debts..seeing them on this list being checked off, paid ofd, resolved, make me fee so fantastic!”',
        videoLink: 'https://www.youtube.com/watch?v=nU8BkdeZ0Sg',
      },
      {
        img: '',
        name: 'Ozzy',
        story:
          '“Right away, I had more money each month because of program costs so much less than what I was paying on my minimums.”',
        videoLink: 'https://www.youtube.com/watch?v=NVxTO5Ea2-Y&t=39s',
      },
    ],
  },
}

export const sliderSettings = {
  infinite: true,
  slidesToScroll: 1,
  speed: 200,
  arrows: false,
  responsive: [
    {
      breakpoint: 360,
      settings: {
        speed: 100,
        slidesToShow: 1,
        centerMode: true,
      },
    },
    {
      breakpoint: 390,
      settings: {
        speed: 100,
        slidesToShow: 1.1,
        centerMode: true,
      },
    },
    {
      breakpoint: 565,
      settings: {
        speed: 100,
        slidesToShow: 1.2,
        centerMode: true,
      },
    },
    {
      breakpoint: 565,
      settings: {
        speed: 100,
        slidesToShow: 2,
        centerMode: true,
      },
    },
    {
      breakpoint: 696,
      settings: {
        speed: 100,
        slidesToShow: 2,
        centerMode: true,
      },
    },
    {
      breakpoint: 767,
      settings: {
        speed: 100,
        slidesToShow: 2,
        centerMode: true,
      },
    },
    {
      breakpoint: 900,
      settings: {
        speed: 100,
        slidesToShow: 3,
        centerMode: true,
      },
    },
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1147,
      settings: {
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 1300,
      settings: {
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 1700,
      settings: {
        arrows: true,
        slidesToShow: 5,
        slidesToScroll: 6,
      },
    },
    {
      breakpoint: 1800,
      settings: {
        arrows: true,
        slidesToShow: 5,
        slidesToScroll: 6,
      },
    },
    {
      breakpoint: 2000,
      settings: {
        arrows: true,
        slidesToShow: 5,
        slidesToScroll: 6,
      },
    },
    {
      breakpoint: 10000,
      settings: {
        arrows: true,
        slidesToShow: 5,
        slidesToScroll: 6,
      },
    },
  ],
}
